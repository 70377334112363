import * as React from "react"
import Layout from "../components/layout"
import IndexPage from "../components/pageComponents/index"
import messages from "../i18n/es"

const IndexPageEs = ({ location }) => (
  <Layout location={location} messages={messages}>
    <IndexPage langKey="es" />
  </Layout>
)

export default IndexPageEs
